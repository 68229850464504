//BRAND
export const name = 'CoursesMaker';
export const company = 'CoursesMaker';
//export const websiteURL = 'https://coursesmaker-cc125.web.app';
export const websiteURL = 'https://coursesmaker.com';
export const serverURL = 'https://coursesmaker-1.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/coursesmaker-cc125.appspot.com/o/online-course.png?alt=media&token=d0504ddf-3510-4aec-8d15-a43ada92de9d';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = false;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 7;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 60;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The Coursesmaker.com has transformed the way I create content, delivering precise and relevant topics with ease. It's an incredible time-saver that boosts both the quality and effectiveness of my courses. A game-changing tool for learners looking to streamline their process and deliver impactful knowledge.";
export const from = "Rohit Nandan";
export const profession = 'Content Creator';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/coursesmaker-cc125.appspot.com/o/cute-boy.png?alt=media&token=e5f9e4e3-fd99-4652-868e-5c78b01f747f';

//PAYPAL
export const paypalPlanIdOne = "P-4DJ8235394547460RM357NLQ";
export const paypalPlanIdTwo = "P-91S306302J852782SM357OCY";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "222599124666-vrp0n98cblv1htarqleg1t8d3lak2uj3.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";

export const socialURL = "https://biolinksapp.com/coursesmaker";